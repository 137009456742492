// PLACEHOLDERS - General placeholders
%reset-box-sizing {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
%clearfix {
	@include clearfix;
}
%appearance-none {
	@include appearance(none);
}
%default-font {
	@include default-font;
}

// PLACEHOLDERS - Headings
%heading {
	font-family: $secondary-font-family;
}
%h1 {
	@extend %heading;
	font-size: $h1-font-size;
	font-weight: $h1-font-weight;
	letter-spacing: $h1-letter-spacing;
}
%h2 {
	@extend %heading;
	font-size: $h2-font-size;
	font-weight: $h2-font-weight;
	letter-spacing: $h2-letter-spacing;
}
%h3 {
	@extend %heading;
	font-size: $h3-font-size;
	font-weight: $h3-font-weight;
}
%h4 {
	@extend %heading;
	font-size: $h4-font-size;
	font-weight: $h4-font-weight;
}
%h5 {
	@extend %heading;
	font-size: $h5-font-size;
	font-weight: $h5-font-weight;
}
%h6 {
	@extend %heading;
	font-size: $h6-font-size;
	font-weight: $h6-font-weight;
}


// PLACEHOLDERS - Layout
%align-center {
	@include align-center;
}
%align-middle {
	@include align-middle;
}
%align-bottom {
	@include align-bottom;
}
%align-absolute-horizontal {
	@include align-absolute-horizontal;
}
%align-absolute-vertical {
	@include align-absolute-vertical;
}
%align-absolute-center {
	@include align-absolute-center;
}
%fluid-constrained {
	@include fluid-constrained;
}
%flex {
	@include flex;
}
%flex-item {
	@include flex-item;
}


// PLACEHOLDERS - Form-related
%form-field {
	@extend %default-font;

	height: 55px;
	padding: $form-field-padding;
	font-size: 16px;
	font-weight: $semi-bold;
	color: $form-field-text-color;
	border: $form-field-border;
	letter-spacing: $form-field-letter-spacing;

	@if (variable_exists(form-field-transition)){
		@include transition($form-field-transition);
	}

	&:hover,
	&:focus {
		@include box-shadow(0 0 3px 0 $light-gray);

		border-color: $form-field-hover-border-color;
	}
}
%form-field-disabled {
	background-color: $form-field-disabled-background-color;
	color: $form-field-disabled-text-color;
	border: $form-field-disabled-border;
	cursor: not-allowed;
}
