// VARIABLES - General
// Colors
$lighter-gray: #e4e4e4;
$light-gray: #a4a4a4;
$dark-gray: #505050;
$promo-color: #EA0122;
$black-color: #000;

$body-text-color: #000;

$border-color-darker: #979797;
$border-color-dark: #e1e1e1;
$border-color-light: #e4e4e4;
$border-color-lighter: #f2f2f2;

// Transition
$default-transition-duration: 0.30s;
$default-transition-timing-function: ease-out;

// Animation
$default-animation-duration: 0.42s;

// Media breakpoints
$breakpoints: (
	"mobile": 520px,
	"tablet": 880px,
	"desktop-sm": 1160px,
	"desktop-md": 1440px,
	"desktop-lg": 1660px
) !default;

// Helpers
$constrained-width: 1200px;
$medium-constrained-width: 1000px;
$small-constrained-width: 924px;

$z-indexes: (
	"flash-message",
	"pop-up",
	"header",
	"pop-up-overlay",
	"navigation",
	"sliding-panel",
	"overlay",
	"loader",
	"loader-overlay",
	"product-list-item-label",
	"product-list-item-hover-elements",
	"product-list-item-image",
	"product-gallery-thumbnail-border",
	"product-gallery-thumbnails-mobile",
	"product-variation-check",
	"product-variation-image"
);

// VARIABLES - Fonts
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

$default-font-family: Montserrat, sans-serif !default;
$default-font-size: 13px !default;
$default-font-weight: $regular !default;
$default-font-line-height: 1.2 !default;

$secondary-font-family: "Montserrat", sans-serif;

$secondary-font-size: 12px;

// VARIABLES - Headings
$h1-font-size: 24px;
$h2-font-size: 21px;
$h3-font-size: 19px;
$h4-font-size: 16px;
$h5-font-size: 14px;
$h6-font-size: 12px;

$h1-font-weight: $regular;
$h2-font-weight: $light;
$h3-font-weight: $light;
$h4-font-weight: $light;
$h5-font-weight: $light;
$h6-font-weight: $light;

$h1-letter-spacing: 1.33px;
$h2-letter-spacing: 1.17px;

// VARIABLES - Links
// Default
$link-text-color: $body-text-color;
$link-text-decoration: none;

// Hover
$link-hover-text-color: $body-text-color;
$link-hover-text-decoration: none;

// Transition (comment this, if you want to stop the transition)
//$link-transition: color $default-transition-duration $default-transition-timing-function;

// VARIABLES - Buttons
// General
$button-border-radius: 0;
$button-padding: 14px 10px 15px;;
$bordered-button-padding: 11px 10px;
$button-mobile-padding: 12px 10px;

// Default
$button-background-color: #000;
$button-text-color: #fff;

$button-border-width: 1px;
$button-border-style: solid;
$button-border-color: #333;
$button-border: $button-border-width $button-border-style $button-border-color;

// Disabled
$button-disabled-background-color: #ccc;
$button-disabled-text-color: #e7e7e7;

$button-disabled-border-width: $button-border-width;
$button-disabled-border-style: $button-border-style;
$button-disabled-border-color: $button-border-color;
$button-disabled-border: $button-disabled-border-width $button-disabled-border-style $button-disabled-border-color;

// Hover
$button-hover-background-color: #525457;
$button-hover-text-color: #fff;

$button-hover-border-width: $button-border-width;
$button-hover-border-style: $button-border-style;
$button-hover-border-color: #000;
$button-hover-border: $button-hover-border-width $button-hover-border-style $button-hover-border-color;

// Transition (comment this, if you want to stop the transition)
$button-transition: color $default-transition-duration $default-transition-timing-function,
background-color $default-transition-duration $default-transition-timing-function,
border $default-transition-duration $default-transition-timing-function;

// VARIABLES - Form fields
// Default
$form-field-padding: 20px 12px 7px 12px;
$form-field-text-color: #000;
$form-field-placeholder-color: #A4A4A4;
$form-field-background-color: #fff;
$form-field-letter-spacing: 0;
$form-field-placeholder-line-height: 16px;

$form-field-border-width: 1px;
$form-field-border-style: solid;
$form-field-border-color: #c0c0c0;
$form-field-border: $form-field-border-width $form-field-border-style $form-field-border-color;

// Disabled
$form-field-disabled-background-color: #f0f0f0;
$form-field-disabled-text-color: #a9a9a9;

$form-field-disabled-border-width: $form-field-border-width;
$form-field-disabled-border-style: $form-field-border-style;
$form-field-disabled-border-color: $form-field-border-color;
$form-field-disabled-border: $form-field-disabled-border-width $form-field-disabled-border-style $form-field-disabled-border-color;

// Hover
$form-field-hover-background-color: $form-field-background-color;
$form-field-hover-text-color: #000;

$form-field-hover-border-color: #000;

// Transition (comment this, if you want to stop the transition)
$form-field-transition: box-shadow $default-transition-duration $default-transition-timing-function,
border $default-transition-duration $default-transition-timing-function;

// Base64-encoded PNG image of an arrow
$form-field-select-arrow: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAHCAYAAAAS9422AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAALtJREFUeNpi/P//PwMIqCgoZgIpDyAOvfvwwS8GLEBZXoEZSM0H4p13HtxfykAEYEJihwCxHxCvgBqEzfBFQBwLxAkMRAJkC3yBeD8QB4IMQrYEyGYEUnOBOAqITwFxALEWMMKCiJGREWQQF5C5FYgdgHgZEMcB8T8gngbEGVDD3YBB+BGmjyQLoK7lB1K7gNgM6urf6IaD1JFtARZLQOAKEDsBDX8NU0ORBUiW7AZidiB2QTacFAsAAgwAgMZJRnqL7s0AAAAASUVORK5CYII=";

// Sprite
$sprite: "/images/sprite.svg";

// ---------------------------------
// Custom variables
$letter-spacing-narrow: 0.67px;
$letter-spacing-medium: 0.78px;
$letter-spacing-wide: 0;

$error-color: $promo-color;
$success-color: #46d430;

$flash-message-success-background-color: #2d9044;
$flash-message-error-background-color: $promo-color;

$navigation-height: 42px;
$mega-menu-background: #414143;

$productGridItemWidth: 372px;
$productGridItemHeight: 561px;

$pop-up-background-color: #fff;
$pop-up-padding: 30px 20px 50px;
$pop-up-mobile-padding: 10px;

// Back to top
$back-to-top-background-color: #000;
