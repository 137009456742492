@import "../config";

.error-page {
	@include fluid-constrained();
	@extend %align-center;
	
	padding: 40px 20px;
	font-size: 0;

	@include media("<=tablet") {
		padding-top: 22px;
	}
	
	.text {
		font-size: 13px;
	}
}