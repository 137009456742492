@mixin media($conditions...) {
	@if (length($conditions) == 0) {
		@content;
	} @else if (length($conditions) > 0) {
		@media #{unquote(parse-expression(nth($conditions, 1)))} {
			// Recursive call
			$sliced: slice($conditions, 2);
			@include media($sliced...) {
				@content;
			}
		}
	}
}

@mixin gradient($startColor: $white, $endColor: $lightergrey, $direction: "vertical", $deg: 45deg) {
	@if ($direction == "horizontal") {
		background-color: $endColor;
		background-image: -webkit-gradient(linear, 0 0, 100% 0, from($startColor), to($endColor));
		background-image: -webkit-linear-gradient(left, $startColor, $endColor);
		background-image: -moz-linear-gradient(left, $startColor, $endColor);
		background-image: -ms-linear-gradient(left, $startColor, $endColor);
		background-image: -o-linear-gradient(left, $startColor, $endColor);
		background-image: linear-gradient(left, $startColor, $endColor);
		background-repeat: repeat-x;
	} @else if ($direction == "vertical") {
		background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor));
		background-image: -webkit-linear-gradient(top, $startColor, $endColor);
		background-color: $endColor;
		background-image: -moz-linear-gradient(top, $startColor, $endColor);
		background-image: -ms-linear-gradient(top, $startColor, $endColor);
		background-image: -o-linear-gradient(top, $startColor, $endColor);
		background-image: linear-gradient(top, $startColor, $endColor);
		background-repeat: repeat-x;
	} @else if ($direction == "angle") {
		background-color: $endColor;
		background-image: -moz-linear-gradient($deg, $startColor, $endColor);
		background-image: -ms-linear-gradient($deg, $startColor, $endColor);
		background-image: -webkit-linear-gradient($deg, $startColor, $endColor);
		background-image: -o-linear-gradient($deg, $startColor, $endColor);
		background-image: linear-gradient($deg, $startColor, $endColor);
		background-repeat: repeat-x;
	}
}

@mixin border-radius($topRight: 0, $bottomRight: null, $bottomLeft: null, $topLeft: null) {
	@if($bottomRight == null && $bottomLeft == null && $topLeft == null) {
		$radius: $topRight;
		-webkit-border-radius: $radius;
		-moz-border-radius: $radius;
		border-radius: $radius;
	} @else {
		-webkit-border-top-right-radius: $topRight;
		-webkit-border-bottom-right-radius: $bottomRight;
		-webkit-border-bottom-left-radius: $bottomLeft;
		-webkit-border-top-left-radius: $topLeft;
		-moz-border-radius-topright: $topRight;
		-moz-border-radius-bottomright: $bottomRight;
		-moz-border-radius-bottomleft: $bottomLeft;
		-moz-border-radius-topleft: $topLeft;
		border-top-right-radius: $topRight;
		border-bottom-right-radius: $bottomRight;
		border-bottom-left-radius: $bottomLeft;
		border-top-left-radius: $topLeft;
	}
}

@mixin box-shadow($args...) {
	-webkit-box-shadow: $args;
	-moz-box-shadow: $args;
	box-shadow: $args;
}

@mixin appearance($appearance){
	-webkit-appearance: $appearance;
	-moz-appearance: $appearance;
	appearance: $appearance;
}

@mixin user-select($user-select){
	-webkit-user-select: $user-select;
	-moz-user-select: $user-select;
	-ms-user-select: $user-select;
	user-select: $user-select;
}

@mixin user-drag($user-drag){
	-webkit-user-drag: $user-drag;
	user-drag: $user-drag;
}

@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($str...) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};
}

@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-o-transition: $args;
	transition: $args;
}

@mixin transform($args...) {
	-webkit-transform: $args;
	-moz-transform: $args;
	-ms-transform: $args;
	-o-transform: $args;
	transform: $args;
}

@mixin transform-origin($args...) {
	-webkit-transform-origin: $args;
	-moz-transform-origin: $args;
	-ms-transform-origin: $args;
	-o-transform-origin: $args;
	transform-origin: $args;
}

@mixin hardware-acceleration($backface: true, $perspective: 1000) {
	@if $backface {
		backface-visibility: hidden;
	}
	perspective: $perspective;
}

// Use the mixins below only in media queries.
// Otherwise, use the corresponding placeholders.
@mixin clearfix {
	*zoom: 1;
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin font-face($name, $url, $weight: 400, $style: normal) {
	@font-face {
		font-family: #{$name};
		font-weight: #{$weight};
		font-style: #{$style};
		src: url('#{$url}.eot');
		src: url('#{$url}.eot#iefix') format("embedded-opentype"),
		url('#{$url}.ttf') format('truetype'),
		url('#{$url}.svg##{$name}') format('svg'),
		url('#{$url}.woff') format('woff');

		@content;
	}
}

@mixin sprite($x: 0, $y: 0, $repeat: no-repeat) {
	background: url($sprite) $repeat $x $y;
}

@mixin default-font {
	font: $default-font-weight #{$default-font-size}/#{$default-font-line-height} $default-font-family;
}

@mixin align-center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

@mixin align-middle {
	position: relative;
	top: 50%;
	@include transform(translateY(-50%));
}

@mixin align-bottom {
	position: relative;
	top: 100%;
	@include transform(translateY(-100%));
}

@mixin align-absolute-horizontal {
	position: absolute;
	left: 50%;
	@include transform(translateX(-50%));
}

@mixin align-absolute-vertical {
	position: absolute;
	top: 50%;
	@include transform(translateY(-50%));
}

@mixin align-absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform(translate(-50%, -50%));
}

@mixin fluid-constrained($width: $constrained-width) {
	width: 100%;
	max-width: $width;
}

@mixin flex {
	font-size: 0;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin flex-item {
	-webkit-box-flex: 1;
	-moz-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}